import React, {useEffect} from 'react'
import {isBrowser} from '../utils/articleUtil'
import queryString from 'query-string'
import {Helmet} from "react-helmet/es/Helmet";
import { addTimeout } from '../utils/timeManager';

function Intermediate() {
    //https://virginpure.com/?AgeGroup&utm_source=article&utm_medium=blog_link&utm_campaign=AgeGroup_sponsored_content
    //http://localhost:8000/intermediate?r=https%3A%2F%2Fvirginpure.com%2F%3FAgeGroup%26utm_source%3Darticle%26utm_medium%3Dblog_link%26utm_campaign%3DAgeGroup_sponsored_content
    /*useEffect(() => {

    }, []);*/

    const handleScriptInject = ({scriptTags}) => {
        if (scriptTags) {
            const scriptTag = scriptTags[0];
            scriptTag.onload = gtmLoaded;
        }
    }

    const gtmLoaded = () => {
        let redirectUrl;
        if (isBrowser() && window.location && window.location.search) {
            const query = queryString.parse(window.location.search);
            if (query && query.r) {
                redirectUrl = query.r
            }
        }
        if (redirectUrl) {
            window.dataLayer = window.dataLayer || [];
            const eventData = {
                'event':'pageview',
                'hostname': window.location.hostname,
                'url': window.location.href,
                'page_Path': window.location.pathname,
                'redirect_url': decodeURI(redirectUrl)
            }
            window.dataLayer.push(eventData);
            addTimeout(() => {
                window.location.href = redirectUrl;
            }, 5);
        } else {
            window.location.href = '/';
        }
    }

    return (
        <>
            <Helmet script={[{src: '/gtm.js'}]}
                    onChangeClientState={(newState, addedTags) => handleScriptInject(addedTags)}>
            </Helmet>
        </>
    );
}

export default Intermediate;
