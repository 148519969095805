const timeouts = [];
const addTimeout = (callback, delay)=>{
    const timeId = setTimeout(callback, delay);
    timeouts.push(timeId);
    return timeId;
};
const cancelAlltimeouts =()=>{
    timeouts.forEach((timeId)=>{
        clearTimeout(timeId);
    });
    timeouts.length = 0;
};
export {addTimeout, cancelAlltimeouts};